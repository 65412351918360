import { FastAverageColor } from "fast-average-color";
import fontColorContrast from "font-color-contrast";
import React, { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  bigScreenBreakpoint,
  bigScreenDetailsBreakpoint,
  filmCoverEndpoint,
  filmCoverRatio,
  hostAddress, userGameCoverWidthSmall,
  userGameDetailsContainerWidth,
  userGameDetailsCoverWidth, userGameMinDetailsDiaryWidth
} from "../utils/constants";
import { UserFilmType } from "../utils/types";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import DetailsModalFooter from "./details-modal-footer";
import GameOverlay from "./game-overlay";
import { UserFilmHeader } from "./user-film-header";
import { UserFilmWatchthrough } from "./user-film-watchthrough";
import BackButton from "./utils/back-button";
import InteractiveIcon from "./utils/interactive-icon";

export const UserFilmDetails : FC<{film: UserFilmType, clearModalData: () => void}> =
    ({ film, clearModalData }) => {

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenDetailsBreakpoint}px)` });
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

      const { variant } = React.useContext(VariantContext);
      const [isLight, setIsLight] =useState<boolean>(false);

      const { favourite, childhood, watchthroughs, favouriteMusic } = film;
      const allWatchthroughs = watchthroughs ?? [];

      const completed = allWatchthroughs.filter((watchthrough)=>
        watchthrough.completed).length > 0;

      const assessFontColor = async () => {
        const fac = new FastAverageColor();
        const color = await fac.getColorAsync(`${hostAddress}${filmCoverEndpoint}${film.film.id}`);
        const fontColor = fontColorContrast(color.hex, 0.8);
        setIsLight(fontColor === "#000000");
      };

      useEffect(() => {
        assessFontColor();
      }, []);

      return (
        <>
          <div
            className={`d-flex gap-${isMobile
              ? 2
              : 4} mb-0 ${!isBigScreen && "flex-wrap"} position-relative overflow-hidden`}>
            <CardBackground
              endpoint={filmCoverEndpoint}
              visible={true}
              id = {film.film.id}
              zIndex={-10}/>
            <GameOverlay
              opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayDetailsOpacity}
              color={colors[variant.theme as keyof typeof colors].bgOverlay}
              zIndex={-5}
              visible={true}/>
            {isBigScreen &&
              <InteractiveIcon
                className={"m-1 text-danger position-absolute top-0 end-0"}
                iconName={"fas fa-circle-xmark"}
                onClick={clearModalData}/>}

            <div
              className={`d-flex ${!isBigScreen && "p-3 w-100 border-top border-1 border-primary"}`}
              style={{ minWidth: isBigScreen
                ? userGameDetailsContainerWidth
                : undefined,
              width: isBigScreen
                ? userGameDetailsContainerWidth
                : undefined }}>
              <Cover
                ratio={filmCoverRatio}
                endpoint={filmCoverEndpoint}
                id={film.film.id}
                width={isMobile
                  ? userGameCoverWidthSmall
                  : userGameDetailsCoverWidth}/>
              <UserFilmHeader
                isMobile={isMobile}
                lightCover={!isLight}
                stroke
                tags={film.tags}
                hideLastHr
                film={film.film}/>
            </div>
            <div className={"ps-3 pe-3 w-100"}>
              <div
                style={{ minWidth: !isMobile
                  ? userGameMinDetailsDiaryWidth
                  : undefined }}
                className={`${!isLight
                  ? "text-shadow-black"
                  : "text-black"} mb-2 text-center mt-2`}>
                Dziennik
              </div>
              <div>
                {film.watchthroughs.map((watchthrough) =>
                  <div
                    className={"mt-2 mb-2"}
                    key={`watchthrough-${watchthrough.id}`}>
                    <UserFilmWatchthrough
                      watchthrough={watchthrough}
                      count={film.watchthroughs.length}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <DetailsModalFooter
            reviews={film.reviews}
            favouriteMusic={favouriteMusic}
            favourite={favourite}
            completed={completed}
            childhood={childhood}
            isGame={false}/>
        </>);
    };