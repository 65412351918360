import { Themes } from "./colors";

export const loadingHeight = 4;
export const loadingType = "bubbles";
export const playTypeIcons = {
/*  "full": "flag",*/
  "full": "bookmark",
  "kid": "baby-carriage",
  "speed": "bolt",
  "test": "flask",
  "multi": "globe",
  "retired": "poo",
  "regular": "infinity",
};

export const playTypeTexts = {
  "full": "zwykłe playthrough",
  "kid": "gra z dzieciństwa",
  "speed": "speedrun",
  "test": "testowana",
  "multi": "multi",
  "retired": "zarzucona",
  "regular": "regularna gra",
};


export enum Modes {
  FirstPlayDate = "first",
  LastPlayDate = "last",
  All = "all",
}

export enum Sort {
  PlayDate = "playDate",
  ReleaseDate = "releaseDate",
  Score = "score",
  Name = "name",
  Added = "createdDate",
}

export enum CustomListSort {
  EntryDate = "entryDate",
  ReleaseDate = "releaseDate",
  Value = "value",
  Priority = "priority",
  Name = "name",
}


export enum LabeledOptions {
  Labeled = "labeled",
  Unlabeled = "unlabeled",
}

export const LabeledOptionsLabels = {
  [LabeledOptions.Labeled]: "Pokaż",
  [LabeledOptions.Unlabeled]: "Ukryj",
};

export const ModeNames = {
  [Modes.FirstPlayDate]: "Najstarsza",
  [Modes.LastPlayDate]: "Najnowsza",
  [Modes.All]: "Wszystkie",
};

export const SortNames = {
  [Sort.PlayDate]: "Data wpisu",
  [Sort.ReleaseDate]: "Data premiery",
  [Sort.Score]: "Ocena",
  [Sort.Name]: "Nazwa",
  [Sort.Added]: "Data dodania",
};


export const CustomListsSortNames = {
  [CustomListSort.EntryDate]: "Data wpisu",
  [CustomListSort.ReleaseDate]: "Data premiery",
  [CustomListSort.Value]: "Ranga",
  [CustomListSort.Priority]: "Pozycja",
  [CustomListSort.Name]: "Nazwa",
};
export const platinumTrophyHeight = 40;
export const badgeHeight = 30;
export const playthroughSlugMaxLength = 100;

const debug = false;
export const hostAddress = debug
  ? "http://localhost:8000"
  :  "https://admin.storyclicks.pl";


const apiPath =  "/api/v1/games";
const filmsApiPath =  "/api/v1/films";
export const listsApiPath =  "/api/v1/lists";
export const gamesEndpoint = apiPath + "/all-games";
export const userGamesEndpoint = apiPath + "/user-games";
export const userFilmsEndpoint = filmsApiPath + "/user-films";
export const cinemaListEndpoint = filmsApiPath + "/cc/";
export const gameCoverEndpoint = apiPath + "/cover/";
export const filmCoverEndpoint = filmsApiPath + "/cover/";

export const bigScreenBreakpoint = 800;
export const bigScreenDetailsBreakpoint = 1400;
export const bigScreenCinemaBreakpoint = 1000;
export const gameHeaderWidth = 200;

export const userGameCoverWidth = 230;
export const userGameCoverWidthSmall = 180;

export const userGameDetailsCoverWidth = 280;
export const customListCoverWidth = 220;

export const userGameContainerWidth
    = userGameCoverWidth + gameHeaderWidth;

export const userGameDetailsContainerWidth
    = userGameDetailsCoverWidth + gameHeaderWidth;

export const minUserGameListItemWidth
    = userGameDetailsCoverWidth + gameHeaderWidth;

export const minUserGameListItemWidthBig
    = userGameDetailsCoverWidth * 2 + gameHeaderWidth;

export const userGameMinDetailsDiaryWidth = 400;

export const detailsModalWidth = 1100;
export const libraryGameContainerWidth = 500;
export const gameCoverRatio = 12/9;
export const filmCoverRatio = 3/2;
export const libraryGameCoverWidth = 220;
export const badScoreThreshold = 55;

export const gameBgZIndex = -10;
export const imageBgOverlayZIndex = -5;

export const defaultVariant = {
  mode: Modes.LastPlayDate,
  sort: Sort.PlayDate,
  divided: false,
  advancedBgs: true,
  randScores: false,
  labeled: true,
  theme: Themes.Dark };

export const playstationPlatforms = ["PS5", "PS4", "PS3"];

export const FilterFlagsTypeLabels = {
  "is2d" : "2D",
  "is3d": "3D",
  "isBasic": "Basic",
  "isImax" : "IMAX",
  "isScreenX" : "ScreenX",
  "is4dx": "4dx",
  "isDubbed" : "Dubbing",
  "isAtmos" : "Atmos",
  "isVIP" : "VIP",
  "isWroclavia": "Wroclavia",
  "isKorona": "Korona",
};