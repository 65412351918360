import React, { FC } from "react";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import { GameType } from "../utils/types";
import TooltipBadge from "./utils/tooltip-badge";

const UserGameHeader : FC<{stroke?: boolean,
    isMobile?: boolean, hideLastHr?: boolean, game: GameType,
     lightCover?: boolean, tags?: string}>
    = ({ game, isMobile,
      hideLastHr, stroke, lightCover,tags }) => {

      const { name, released, series, developer, isExpansion, subseries } = game;
      const allSeries = [series, ...subseries];
      const { variant } = React.useContext(VariantContext);
      return (
        <div
          className={`${stroke && `${lightCover
            ? "text-shadow-black"
            : "text-black"}`} m-2 mb-0 justify-content-between 
          d-flex flex-column align-items-end text-end w-100`}>
          <div>
            {!isMobile ?
              <p>
                <span
                  className={"p-0 m-0 fw-bold position-relative"}
                  style={{
                    top: -3,
                    left: -7
                  }}>
                  {isExpansion && <TooltipBadge
                    tooltipText={"dodatek do gry"}
                    text={"E"}/>
                  }
                </span>
                <span className={"fw-bold fs-5"}>
                  {name}
                </span>
              </p>
              : <span className={"fw-bold fs-5"}>
                {name}
              </span>}

            {!isMobile &&
              <span
                style={{
                  fontSize: 16,
                  fontStyle: "italic" }}>
                {series}
                {subseries.length > 0 &&
                <span className={"ms-2"}>
                  <TooltipBadge
                    text={`+${subseries.length}`}
                    tooltipText={allSeries.join(", ")}/>
                </span>}
              </span> }
          </div>
          <div className={"d-flex flex-column align-items-end"}>
            {tags &&
            <div
              className={"mb-1 d-flex gap-1"}
              style={{ maxWidth: 150 }} >
              {tags.split(";").map((tag, id)=>
                <span
                  style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}95` }}
                  key={id + tag}
                  className={"fs-7 text-white rounded-2 pt-1 pb-1 p-2"}>
                  {`${tag}`}
                </span>
              )}
            </div>}

            {tags &&
            <hr
              className={"mb-1 mt-1"}
              style={{ width: 70 }}/> }

            {!isMobile && <>
              <div
                className={"mb-1"}
                style={{ maxWidth: 150 }}>
                {game.genres.map((genre, id) =>
                  <span
                    key={id + genre}
                    className={"fs-7"}>
                    {`${genre}${id !== game.genres.length - 1
                      ? ", "
                      : ""}`}
                  </span>
                )}
              </div>
              <hr
                className={"mb-1 mt-1"}
                style={{ width: 70 }}/>
            </>
            }

            <p
              className={"mb-1 mt-1"}
              style={{ fontSize: 14 }}>
              <span>{released}</span>
              <br/>
              <span>{developer}</span>
            </p>
            {!hideLastHr
              ? <hr
                className={"m-0 mt-1"}
                style={{ width: 70 }}/>
              : <div
                className={"m-0 mt-3"}
                style={{ width: 70 }}/>}

          </div>
        </div>

      );
    };

export default UserGameHeader;