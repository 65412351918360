import React, { FC } from "react";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import { FilmType, GameType } from "../utils/types";
import TooltipBadge from "./utils/tooltip-badge";

export const UserFilmHeader : FC<{stroke?: boolean, isMobile?: boolean,
    hideLastHr?: boolean, film: FilmType,
     lightCover?: boolean, tags?: string}>
    = ({ film, isMobile, hideLastHr, stroke, lightCover,tags }) => {

      const defaultLanguage = "eng";
      const { titles,duration, year, series, director, isShort, subseries } = film;
      const allSeries = [series, ...subseries];
      const { variant } = React.useContext(VariantContext);

      const mainTitle = titles.filter(title=>
        title.language === defaultLanguage)[0].title;

      const otherTitles = titles.filter(title=>
        title.language !== defaultLanguage).map(title => 
        title.title);

      return (
        <div
          className={`${stroke && `${lightCover
            ? "text-shadow-black"
            : "text-black"}`} m-2 mb-0 justify-content-between 
          d-flex flex-column align-items-end text-end w-100`}>
          <div>
            {!isMobile ?
              <p>
                <span
                  className={"p-0 m-0 fw-bold position-relative"}
                  style={{
                    top: -3,
                    left: -7
                  }}>
                  {isShort && <TooltipBadge
                    tooltipText={"film krótkometrażowy"}
                    text={"S"}/>
                  }
                </span>
                <span className={"fw-bold fs-5"}>
                  {mainTitle}
                </span>
              </p>
              : <span className={"fw-bold fs-5"}>
                {mainTitle}
              </span>
            }
            {!isMobile && <>
              <span
                style={{
                  fontSize: 14,
                  fontStyle: "italic"
                }}>
                {otherTitles[0]}
                {otherTitles.length > 1 &&
                <span className={"ms-2"}>
                  <TooltipBadge
                    text={`+${otherTitles.length - 1}`}
                    tooltipText={otherTitles.join(", ")}/>
                </span>}
              </span>
              <br/>
              <span
                className={"ms-2"}
                style={{ fontSize: 14,
                  fontStyle: "italic" }}>
                {duration}
                {" "}
                min
              </span>
              <span
                className={"ms-1 fa-solid fa-clock"}
                style={{ fontSize: 12 }}/>

              {hideLastHr &&
              <>
                <hr/>
                <span
                  style={{ fontSize: 16,
                    fontStyle: "italic" }}>
                  {series}
                  {subseries.length > 0 &&
                  <span className={"ms-2"}>
                    <TooltipBadge
                      text={`+${subseries.length}`}
                      tooltipText={allSeries.join(", ")}/>
                  </span>}
                </span>
              </>
              }
            </>}
          </div>

          <div className={"d-flex flex-column align-items-end"}>
            {tags &&
            <div
              className={"mb-1 d-flex gap-1"}
              style={{ maxWidth: 150 }} >
              {tags.split(";").map((tag, id)=>
                <span
                  style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}95` }}
                  key={id + tag}
                  className={"fs-7 text-white rounded-2 pt-1 pb-1 p-2"}>
                  {`${tag}`}
                </span>
              )}
            </div>}

            {tags &&
            <hr
              className={"mb-1 mt-1"}
              style={{ width: 70 }}/> }

            {!isMobile && <>
              <div
                className={"mb-1"}
                style={{ maxWidth: 150 }} >
                {film.genres.map((genre, id)=>
                  <span
                    key={id + genre}
                    className={"fs-7"}>
                    {`${genre}${id !== film.genres.length - 1
                      ? ", "
                      : ""}`}
                  </span>
                )}
              </div>
              <hr
                className={"mb-1 mt-1"}
                style={{ width: 70 }}/>
            </>}
            <p
              className={"mb-1 mt-1"}
              style={{ fontSize: 14 }}>
              <span>{year}</span>
              <br/>
              <span>{director}</span>
            </p>
            {!hideLastHr
              ? <hr
                className={"m-0 mt-1"}
                style={{ width: 70 }}/>
              : <div className={"m-0 mt-3"}></div>}

          </div>
        </div>

      );
    };