import "@fortawesome/fontawesome-free/css/all.css";

import { Navigate,Route, Routes } from "react-router-dom";

import CinemaList from "./components/cinema-list";
import LibraryGameList from "./components/library-game-list";
import TodoList from "./components/todo-list";
import { UserCustomList } from "./components/user-custom-list";
import { UserCustomLists } from "./components/user-custom-lists";
import { UserFilmList } from "./components/user-film-list";
import UserGameList from "./components/user-game-list";
import Layout from "./layout/layout";
import { RoutePaths } from "./utils/routes";

const App = () => { 
  return (
    <Routes>
      <Route
        path={"/"}
        element={<Layout/>}>
        <Route
          index
          element={<UserFilmList/>}/>
      </Route>
      <Route
        path={RoutePaths.TODO_SUB_ROUTE}
        element={<TodoList/>}/>
      <Route
        path={RoutePaths.GAMES_HOME_ROUTE}
        element={<Layout/>}>
        <Route
          path={RoutePaths.USER_GAMES_SUB_ROUTE}
          element={<UserGameList/>}/>
        <Route
          path={RoutePaths.ALL_GAMES_SUB_ROUTE}
          element={<LibraryGameList/>}/>
      </Route>
      <Route
        path={RoutePaths.LISTS_ROUTE}
        element={<Layout/>}>
        <Route
          path={RoutePaths.LISTS_ROUTE}

          element={<UserCustomLists/>}/>
        <Route
          path={RoutePaths.LISTS_ROUTE + ":id"}
          element={<UserCustomList />} />

      </Route>
      <Route
        path={RoutePaths.FILMS_HOME_ROUTE}
        element={<Layout/>}>
        <Route
          path={RoutePaths.USER_FILMS_SUB_ROUTE}
          element={<UserFilmList/>}/>
      </Route>

      <Route
        path={RoutePaths.CINEMA_ROUTE}
        element={<Layout/>}>
        <Route
          path={RoutePaths.CINEMA_ROUTE}
          element={<CinemaList/>}/>
      </Route>
    </Routes>
  );
};

export default App;
