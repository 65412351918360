import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import useDetailsHistory from "../hooks/use-details-history";
import VariantContext from "../layout/context/variantContext";
import {
  hostAddress,
  userGamesEndpoint
} from "../utils/constants";
import { DetailsModalType, UserGameSetEntryType, UserGameSetType, UserGameType } from "../utils/types";
import { DetailsModal } from "./details-modal";
import { FilterPanel } from "./filter-panel";
import UserGameDetails from "./user-game-details";
import UserGameSet from "./user-game-set";
import CommonLoading from "./utils/common-loading";

const UserGameList = () => {
  const [gameData, setGameData ]
      = useState<Array<UserGameSetType>>([]);

  const [filter, setFilter]
      = useState<string>("");

  const [expansions, setExpansions]
      = useState<boolean>(true);

  const [random, setRandom]
      = useState<number|undefined>(undefined);

  const [coverAttack, setCoverAttack]
      = useState<boolean>(false);

  const [reverseSort, serReverseSort]
      = useState<boolean>(false);

  const [modalData, setModalData]
      = useState<DetailsModalType|null>(null);

  const { variant } = React.useContext(VariantContext);
  const { mode, labeled, sort } = variant;

  useDetailsHistory(modalData, setModalData);

  const clearModalData = useCallback(()=>{
    setModalData(null);
  }, []);

  const unprocessedFlat : UserGameSetEntryType[] = useMemo(()=>
    gameData.reduce((accumulator: UserGameSetEntryType[], set)=>
      [...set.entries, ...accumulator],  [] ),  [gameData] );

  const processedGameData = useMemo(() => {

    let processedData = gameData.map((set : UserGameSetType)=>
      ({ label: set.label,
        entries: set.entries.filter((entry: UserGameSetEntryType)=>
          (entry.gameData.filterValue.includes(filter.toLowerCase()) && (expansions
            ? true
            : !(entry.gameData.game.isExpansion) ))) }));

    processedData = processedData.filter((set: UserGameSetType) =>
      set.entries.length > 0);

    if (reverseSort){
      processedData = reverseSort
        ?  processedData.map((set : UserGameSetType)=>
          ({ label: set.label,
            entries: set.entries.reverse() }))
        : processedData;

      processedData.reverse();
    }

    let randomEntrySet : UserGameSetType[]|undefined = undefined;

    if(random !== undefined){
      randomEntrySet = [
        { label: "Lucky Hit",
          entries: [unprocessedFlat[random]] }
      ];
    }

    return randomEntrySet ?? processedData;
  }, [filter, gameData, expansions, reverseSort, random]);

  const entryCount = processedGameData.reduce((accumulator: number, set)=>
    set.entries.length + accumulator,  0 );

  const completedCount = processedGameData.reduce((accumulator: number, set)=>
    set.entries.filter(entry =>
      entry.gameData.playthroughs.some(playthrough=>
        playthrough.completed)).length + accumulator,  0 );

  const allEntriesCount = processedGameData.reduce((accumulatorMain: number, set)=>
    set.entries.reduce((accumulatorSub: number, entry) =>
      entry.gameData.playthroughs.length + accumulatorSub, 0) + accumulatorMain,  0 );

  useEffect(() => {
    Modal.setAppElement("body");
    document.body.style.overflow = modalData
      ? "hidden"
      : "unset";
  }, [modalData]);

  const infoText = "Wyświetlanie kart w przypadku wielu playthrough jednej gry:\n\n" +
      "najnowsza - brak duplikatów kart, tylko najświeższe playthrough\n\n" +
      "najstarsza - brak duplikatów kart, tylko najstarsze playthrough\n\n" +
      "wszystkie - zduplikowane karty, wszystkie playthrough";

  const savedUser = localStorage.getItem("user") ?? "shichi7";

  const fetchGameData = () => {
    setRandom(undefined);
    setGameData([]);
    fetch(`${hostAddress}${userGamesEndpoint}/${savedUser}/?sort=${sort}&labeled=${labeled}&mode=${mode}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const preFilteredSets = data.map((set : UserGameSetType)=>
          ({ label: set.label,
            entries: set.entries.filter((entry: UserGameSetEntryType)=>
              entry.gameData.playthroughs.length > 0) }));

        setGameData(preFilteredSets);
      });
  };

  const onToggleSortDirection = () => {
    serReverseSort(!reverseSort);
  };

  useEffect(() => {
    fetchGameData();
  }, [variant]);

  return (
    <div className={"container-fluid mt-2"}>
      {gameData.length === 0 ?
        <div className={"d-flex justify-content-center"}>
          <CommonLoading/>
        </div>
        : <>
          <FilterPanel
            cardFilterInfoText={infoText}
            coverAttack={coverAttack}
            setCoverAttack={setCoverAttack}
            setRandom={setRandom}
            gameCount={unprocessedFlat.length}
            reverseSort={reverseSort}
            fetchData={fetchGameData}
            onToggleSortDirection={onToggleSortDirection}
            setFilter={setFilter}/>

          <div>
            <div className={"d-flex justify-content-center pb-2 gap-2"}>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Gry w dzienniku: ${entryCount}`}
              </span>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Wpisy w dzienniku: ${allEntriesCount}`}
              </span>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Ukończone gry: ${completedCount}`}
              </span>
            </div>
            {(processedGameData.length > 0 ?
              processedGameData.map((set) =>
                <UserGameSet
                  coverAttack={coverAttack}
                  setModalData={setModalData}
                  key={`set-${set.label}`}
                  gameSet={set}/>
              )
              : <h4 className={"text-center mt-3"}>Where gierki :(</h4>
            )}
          </div>

        </> }

      {modalData &&
      <DetailsModal clearModalData={clearModalData}>
        <UserGameDetails
          game={modalData as UserGameType}
          clearModalData={clearModalData}/>
      </DetailsModal>
      }
    </div>
  );
};

export default UserGameList;