import React, { FC } from "react";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import { playthroughSlugMaxLength } from "../utils/constants";
import { WatchthroughType } from "../utils/types";
import CustomTooltip from "./utils/custom-tooltip";
import TooltipBadge from "./utils/tooltip-badge";

export const UserFilmWatchthrough : FC<{watchthrough: WatchthroughType,
    count: number, previewFlag?: boolean }> =
    ({ watchthrough, count, previewFlag }) => {

      const { variant } = React.useContext(VariantContext);

      const lastIndex = count - 1;
      const multiplePlaythroughs = lastIndex > 0;

      const firstPlaythroughTooLong = previewFlag && watchthrough.description.length > playthroughSlugMaxLength;

      return (
        <div
          style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}95` }}
          className={"playthroughs-container p-2 ps-3 pe-3 position-relative fs-7"}>
          {multiplePlaythroughs && previewFlag &&
          <span className={"position-absolute end-0 top-0 pt-1"}>
            <TooltipBadge
              className={"rounded-2 rounded-end-0 rounded-top-0 p-1 pe-2 ps-2"}
              text={`+${lastIndex}`}
              tooltipText={"liczba pozostałych wpisów"}/>
          </span>}

          <div
            className={"d-flex align-items-center mb-1 mt-1 justify-content-between p-1"}>
            <div
              style={{ minWidth: 65 }}>

              <span>
                {watchthrough.dateFinished === "01-01-1900"
                  ? "brak daty"
                  : watchthrough.dateFinished}
              </span>
            </div>
            <div
              className={"flex-grow-1 ps-2 text-wrap text-break ms-4"}>
              <span>
                {firstPlaythroughTooLong
                  ? `${watchthrough.description.substring(0, playthroughSlugMaxLength)} [...]`
                  : watchthrough.description}
              </span>
            </div>
            <div className={"fs-7 text-nowrap d-none"}>
              <CustomTooltip
                text={watchthrough.completed
                  ? "ukończono"
                  : "nie ukończono lub nie dotyczy"}>
                <span
                  className={`ps-1 pe-1 fs-6 fa-${watchthrough.completed
                    ? "solid"
                    : "regular"} fa-flag`}>
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>
      );
    };
